import Vue from 'vue'
import Vuex from 'vuex'
import { getSessionStorage, setSessionStorage } from '@/utils/storage.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openId: getSessionStorage('openId'),
    isLogin: getSessionStorage('isLogin'),
    projectCode: getSessionStorage('projectCode'),
    qrCode: getSessionStorage('qrCode'),
    isLocation: getSessionStorage('isLocation')
  },
  getters: {
    projectCode: state => {
      return state.projectCode
    },
    qrCode: state => {
      return state.qrCode
    },
    openId: state => {
      return state.openId
    },
    isLocation: state => {
      return state.isLocation
    }
  },
  mutations: {
    projectCode(state, payload) {
      state.projectCode = payload
      setSessionStorage('projectCode', payload)
    },
    qrCode(state, payload) {
      state.qrCode = payload
      setSessionStorage('qrCode', payload)
    },
    openId(state, payload) {
      state.openId = payload
      setSessionStorage('openId', payload)
      setSessionStorage('isLogin', true)
    },
    isLocation(state, payload) {
      state.isLocation = payload
      setSessionStorage('isLocation', payload)
    }
  },
  actions: {},
  modules: {}
})
