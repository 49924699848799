<template>
  <div class="container">
    <img :src="$getConfig('authLoadingBg')" width="100%" alt="" />
    <BaseNav
      :indexs="[
        {
          index: 1,
          tracking: { userVisit: '42', sourceFlag: '9' }
        },
        {
          index: 2,
          tracking: { event: 'launch', userVisit: '42', sourceFlag: '10' }
        }
      ]"
    />
    <div class="overlay" v-show="showOverlay"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { stringify } from 'qs'
import setting from '@/setting.js'

import {
  checkProject,
  checkCode,
  wxCode,
  userinfo,
  locationParams,
  location,
  //projectStage
} from '@/api/common.js'

const wx = require('weixin-js-sdk')

import { setSessionStorage, getSessionStorage } from '@/utils/storage.js'

export default {
  name: 'Auth',
  data() {
    return {
      code: '',
      showOverlay: false
    }
  },
  computed: {
    ...mapGetters(['projectCode', 'qrCode', 'isLocation'])
  },
  watch: {
    '$route.query.code': {
      handler: function (code) {
        if (code) {
          this.code = code
          this.getProjectStage(() => {
            this.showOverlay = true
            this.getUserinfo()
          })
        } else {
          let { projectCode, qrCode } = this.$route.params

          if (!projectCode && !qrCode) {
            return
          }
          this.$sendTracking({
            projectCode,
            qrCode,
            userVisit: 13
          })
          this.$store.commit('projectCode', projectCode)
          this.$store.commit('qrCode', qrCode)
          this.getCheckProject()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getProjectStage(cb) {
      /* projectStage().then(({ data }) => {
        setSessionStorage('stage', data.stage)
        cb && cb()
      }) */
      setSessionStorage('stage', 1)
      cb && cb()
    },
    // 获取项目校验
    getCheckProject() {
      checkProject({
        projectCode: this.projectCode
      }).then(({ data }) => {
        //获取微信sdk信息
        this.getWxJSSDKConfig({
          projectCode: this.projectCode
        })
        //0=活动未开始，1=活动已开始，2=活动已结束，3=活动不存在，4=活动状态未知
        setSessionStorage('projectStatus', data.status)
        if (data.status == 0 || data.status == 2) {
          let isLocation = data.location
          this.$store.commit('isLocation', isLocation)
          // 获取码
          this.getCheckCode()
        } else if (data.status == 1) {
          let isLocation = data.location
          this.$store.commit('isLocation', isLocation)
          // 获取码
          this.getCheckCode()
          console.log(data)
        } else if (data.status == 5) {
          this.$router.push({
            name: 'projectState',
            query: {
              projectStateImg: data.endImg
            }
          })
        } else {
          this.$router.push({
            name: 'projectState',
            query: {
              status: data.status,
              projectStateImg: data.activityImg
            }
          })
        }
      })
    },
    // 获取码
    getCheckCode() {
      checkCode({
        qrCode: this.qrCode
      }).then(({ data }) => {
        // 0正确数据 1已关联待激活 2已激活 待进入活动10 活动99 无效
        if (data.status == 10) {
          // 获取微信code
          this.getWxCode()
        } else {
          data._title = setting.title
          const codeStateURL = {
            1: `${process.env.VUE_APP_QCODESTATE_URL}?${stringify(data)}`,
            0: `${process.env.VUE_APP_CODESTATE_URL}?${stringify(data)}`
          }
          const verifyPageType = data.verifyPageType
          if (verifyPageType) {
            window.location.href = codeStateURL[verifyPageType]
          }
        }
      })
    },
    // 获取微信code
    getWxCode() {
      wxCode({
        projectCode: this.projectCode,
        qrCode: this.qrCode
      }).then(({ data }) => {
        console.log(data)
        let appId = data.appID
        let redirectUri = encodeURIComponent(window.location.href.split('#')[0])
        let scope = data.scope
        let httpUrl = data.oauthCodePrefix
        let state = this.projectCode + '/' + this.qrCode
        //location.href = `${httpUrl}appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect `;
        let url = `${httpUrl}appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
        console.log(url)
        window.location.href = url
      })
    },
    // 获取用户信息
    getUserinfo() {
      userinfo({
        code: this.code
      }).then(({ data }) => {
        console.log(data)
        this.$store.commit('openId', data.openId)
        // 获取微信定位所需参数
        this.getLocationParams()
      })
    },
    // 获取微信sdk信息
    getWxJSSDKConfig(params, cb) {
      locationParams(params).then(({ data }) => {
        var timestamp = data.timestamp
        var appId = data.appId
        var nonceStr = data.nonceStr
        var signature = data.signature
        console.log(wx)
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: appId, // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名，见附录1
          jsApiList: ['getLocation', 'hideOptionMenu'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          openTagList: ['wx-open-launch-weapp'] //可选，需要使用的开放标签列表
        })
        wx.ready(() => {
          wx.hideOptionMenu()
          cb && cb()
        })
      })
    },
    // 获取微信定位所需参数
    getLocationParams() {
      console.log('get isLocation', this.isLocation)
      if (this.isLocation) {
        this.getWxJSSDKConfig(
          {
            projectCode: this.projectCode
          },
          () => {
            wx.getLocation({
              debug: true,
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: ({ latitude, longitude }) => {
                this.getLocation(latitude, longitude)
              },
              cancel: () => {
                console.log('取消 getLocation')
                this.getLocation()
              },
              fail: () => {
                console.log('失败 getLocation')
                this.getLocation()
              }
            })
          }
        )
      } else {
        this.toWhichPage()
      }
    },
    // 根据经纬度获取位置信息
    getLocation(lat = '', lng = '') {
      location({
        lat,
        lng
      }).then(() => {
        this.toWhichPage()
      })
    },
    //去哪个页面
    toWhichPage() {
      //0=活动未开始，1=活动已开始，2=活动已结束，3=活动不存在，4=活动状态未知
      let projectStatus = getSessionStorage('projectStatus')

      let routeNameMap = {
        0: 'activityNotStart',
        1: 'Index',
        2: 'activityEnd'
      }

      this.$router.push({
        name: routeNameMap[projectStatus]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  img {
    display: block;
    width: 100%;
  }
  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 12;
  }
}
</style>
