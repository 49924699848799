<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

#app {
  font-family: 'Microsoft YaHei', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 72px;
  line-height: 72px;
  background: linear-gradient(0deg, #f69610, #f2e641);
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(127, 127, 127, 0.4);
  border-radius: 36px !important;
  font-size: 24px;
  font-weight: bold;
  //color: #e7471f;
  color: #934E05;
  text-align: center;
}

.link {
  color: blue;
  text-decoration: underline;
}
</style>
