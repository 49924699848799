import axios from 'axios'
import { Toast } from 'vant'
import Qs from 'qs'
import { imgPathAddDomain } from '@/utils/index.js'
//import store from '@/store';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  //baseURL: 'http://rap2api.taobao.org/app/mock/294648' + '/refactor/zhanma',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] =
      'application/x-www-form-urlencoded;charset=UTF-8'
    config.data = Qs.stringify(config.data)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    console.log('response', res)
    if (res.code != 200) {
      // 登陆过期
      if (res.code == 900) {
        Toast({
          message: '登录状态超时，请重新扫码进入',
          duration: 0,
          forbidClick: true
        })
        return
      }
      Toast(res.msg)
      return Promise.reject(res.msg || 'Error')
    } else {
      if (response.config.imgPathAddDomain) {
        return imgPathAddDomain(res)
      } else {
        return res
      }
    }
  },
  error => {
    console.log('err' + error) // for debug
    Toast(error.message)
    return Promise.reject(error)
  }
)

export default service
