import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../views/auth/auth.vue'
import { addWxConfig } from '@/utils/wxConfig.js'
import setting from '@/setting.js'

// 解决当前路由跳转当前路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/:qrCode/:projectCode',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/index',
    name: 'Index',
    component: () =>
      import(/* webpackChunkName: "index" */ '../views/index/index.vue')
  },
  {
    path: '/activity-not-start',
    name: 'activityNotStart',
    component: () =>
      import(
        /* webpackChunkName: "activity-not-start" */ '../views/activity-not-start/activity-not-start.vue'
      )
  },
  {
    path: '/activity-end',
    name: 'activityEnd',
    component: () =>
      import(
        /* webpackChunkName: "activity-end" */ '../views/activity-end/activity-end.vue'
      )
  },
  {
    path: '/scan-log',
    name: 'ScanLog',
    component: () =>
      import(
        /* webpackChunkName: "scan-log" */ '../views/scan-log/scan-log.vue'
      )
  },
  {
    path: '/project-state',
    name: 'projectState',
    component: () =>
      import(
        /* webpackChunkName: "project-state" */ '../views/project-state/project-state.vue'
      )
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/' + process.env.VUE_APP_ROUTER_BASE,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = (to.meta && to.meta.title) || setting.title
  next()
})

let notWxConfigList = ['Auth']

router.afterEach(to => {
  if (notWxConfigList.indexOf(to.name) == -1) {
    addWxConfig()
  }
})

export default router
