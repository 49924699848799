var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('img',{attrs:{"src":_vm.$getConfig('authLoadingBg'),"width":"100%","alt":""}}),_c('BaseNav',{attrs:{"indexs":[
      {
        index: 1,
        tracking: { userVisit: '42', sourceFlag: '9' }
      },
      {
        index: 2,
        tracking: { event: 'launch', userVisit: '42', sourceFlag: '10' }
      }
    ]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOverlay),expression:"showOverlay"}],staticClass:"overlay"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }