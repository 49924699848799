import Vue from 'vue'
import { isFunction, isPing, isGuan } from '../utils'
import { getSessionStorage } from '@/utils/storage.js'

function createActivityRulesTitle(val) {
  return `2023年战马消费者促销活动-${val}`
}
function createTipsDialogContent(val) {
  return `此二维码已被扫超过30次，建议您通过活动热线登记核实${val}的真伪，感谢您的支持~`
}
function createGuanActivityRulesContent(target) {
  return `<p class="p1">产品范围： 310mL战马能量型维生素饮料</p>
  为感谢广大消费者的支持和厚爱，即日起至2024年4月30日止，凡在活动开展地区购买战马能量型维生素饮料罐装促销装产品，发现拉环上印有“1元乐享”字样即可凭该完整拉环加1元人民币购买同规格战马能量型维生素饮料1罐。<br />
  “1元乐享”数量：${target.num}万个<br />

  <p class="p1">活动说明：</p>
  1.“1元乐享”换购方式：凭完整“1元乐享”拉环至指定换购网点加1元人民币购买同规格战马能量型维生素饮料1罐。<br />
  2.换购截止时间为2024年4月30日，逾期视为自动放弃。<br />
  3.活动中奖率40%。 <br />
  4.换购商品市场总价值约${target.commodityValue}万元人民币。<br />
  5.活动区域：除广州市、佛山市、清远市、韶关市、肇庆市、中山市、珠海市、深圳市、东莞市、惠州市以外的中国大陆地区。<br />
  <p class="p1">注意事项：</p>
  请在参加本活动前仔细阅读本规则中的各项条款，如果您参加了本活动，则战马公司认为您已理解并同意该规则中的各条款。<br />
  1.“1元乐享”拉环换购时需保持清洁、完整，拉环仅有舌片不予换购。<br />
  2.换购饮料不可兑换为现金。<br />
  3.战马公司对本次活动促销产品及其附属物（如产品包装、二维码等）的真实性和有效性有鉴定及确认权。<br />
  4.扫码查询链接所到商户若无法换购饮料，可拨打活动热线（400-818-5599）咨询，战马公司可安排消费者进行换购、以确保消费者换购权益<br />
  5.拉环图样、促销产品以实物为准，活动站点及相关宣传资料上提供的图片仅供参考。<br />
  6.本促销产品总数为罐装${target.promotionalCommodityNum}万罐，如遇不可抗力事件，包括但不限于地震、台风、水灾或其他自然灾害、火灾、爆炸事故、骚乱、战争、疫病、政府政策的改变、任何国家主管机关就此次活动做出任何判决、决定、命令，或未能合理控制的任何其他不可预见事件，战马公司有权在不事先通知的前提下变更或取消本活动。<br />
  7.如果参加者在活动中使用任何不正当的手段参加活动，战马公司有权在不事先通知的情况下取消其参加活动的资格，同时保留依法追究其法律责任的权利。<br />
  8.战马公司保留对争议和异议的处理权利。战马公司对于消费者因参加本活动所发生的任何直接、间接费用和产生的任何损害不负任何责任。<br />
  9.本规则的解释与适用，以及与规则有关的任何争议，均以中华人民共和国法律为依据。<br />
  活动咨询热线：400-818-5599（工作日：8:30-11:30、13:00-17:30）<br />
  战马官方网站：www.warhorsechina.com.cn`
}

function createPingActivityRulesContent(target) {
  return `<p class="p1">产品范围： 400mL战马能量型维生素饮料</p>
  为感谢广大消费者的支持和厚爱，即日起至2024年4月30日止，凡在活动开展地区购买战马能量型维生素饮料瓶装促销装产品，发现瓶盖上印有“1元乐享”字样即可凭该完整瓶盖加1元人民币购买同规格战马能量型维生素饮料1瓶。
  <br />
  “1元乐享”数量：${target.num}万个 <br />
  <p class="p1">活动说明： </p>
  1.“1元乐享”换购方式：凭完整“1元乐享”瓶盖至指定换购网点加1元人民币购买同规格战马能量型维生素饮料1瓶。<br />
  2.换购截止时间为2024年4月30日，逾期视为自动放弃。<br />
  3.活动中奖率40%。 <br />
  4.换购商品市场总价值约${target.commodityValue}万元人民币。<br />
  5.活动区域：中国大陆地区。<br />
  <p class="p1">注意事项：</p>
  请在参加本活动前仔细阅读本规则中的各项条款，如果您参加了本活动，则战马公司认为您已理解并同意该规则中的各条款。<br />
  1.“1元乐享”瓶盖换购时需保持清洁、完整。<br />
  2.换购饮料不可兑换为现金。<br />
  3.战马公司对本次活动促销产品及其附属物（如产品包装、二维码等）的真实性和有效性有鉴定及确认权。<br />
  4.扫码查询链接所到商户若无法换购饮料，可拨打活动热线（400-818-5599）咨询，战马公司可安排消费者进行换购、以确保消费者换购权益。<br />
  5.瓶盖图样、促销产品以实物为准，活动站点及相关宣传资料上提供的图片仅供参考。<br />
  6.本促销产品总数瓶装${target.promotionalCommodityNum}万瓶，如遇不可抗力事件，包括但不限于地震、台风、水灾或其他自然灾害、火灾、爆炸事故、骚乱、战争、疫病、政府政策的改变、任何国家主管机关就此次活动做出任何判决、决定、命令，或未能合理控制的任何其他不可预见事件，战马公司有权在不事先通知的前提下变更或取消本活动。<br />
  7.如果参加者在活动中使用任何不正当的手段参加活动，战马公司有权在不事先通知的情况下取消其参加活动的资格，同时保留依法追究其法律责任的权利。<br />
  8.战马公司保留对争议和异议的处理权利。战马公司对于消费者因参加本活动所发生的任何直接、间接费用和产生的任何损害不负任何责任。<br />
  9.本规则的解释与适用，以及与规则有关的任何争议，均以中华人民共和国法律为依据。<br />
  活动咨询热线：400-818-5599（工作日：8:30-11:30、13:00-17:30）<br />
  战马官方网站：www.warhorsechina.com.cn`
}

function createActivityRulesConfirmStorage(key) {
  return `isActivityRulesConfirm${key}`
}

const config = {
  // 罐装
  guan: {
    activityRulesTitle: () => createActivityRulesTitle('罐装'),
    tipsDialogContent: () => createTipsDialogContent('拉环'),
    // 一期
    oneStage: {
      activityRulesContent: () =>
        createGuanActivityRulesContent({
          //“1元即享”数量：${target.num}万个
          num: '4800',
          //4.换购商品市场总价值约${target.commodityValue}万元人民币
          commodityValue: '28800',
          //6.本促销产品总数为罐装${target.promotionalCommodityNum}万罐
          promotionalCommodityNum: '12000'
        }),
      activityRulesConfirmStorage: () =>
        createActivityRulesConfirmStorage('GuanOneStage'),
      authLoadingBg: require('@/assets/oneStage/guan/auth.png'),
      indexBg: require('@/assets/oneStage/guan/index.png'),
      activityNotStartBg: require('@/assets/oneStage/guan/auth.png'),
      activityEndBg: require('@/assets/oneStage/guan/activity-end.png')
    }
  },
  // 瓶装
  ping: {
    activityRulesTitle: () => createActivityRulesTitle('瓶装'),
    tipsDialogContent: () => createTipsDialogContent('瓶盖'),
    // 一期
    oneStage: {
      activityRulesContent: createPingActivityRulesContent({
        //“1元即享”数量：${target.num}万个
        num: '360',
        //4.换购商品市场总价值约${target.commodityValue}万元人民币
        commodityValue: '1800',
        //6.本促销产品总数为罐装${target.promotionalCommodityNum}万罐
        promotionalCommodityNum: '900'
      }),
      activityRulesConfirmStorage: () =>
        createActivityRulesConfirmStorage('PingOneStage'),
      authLoadingBg: require('@/assets/oneStage/ping/auth.png'),
      indexBg: require('@/assets/oneStage/ping/index.png'),
      activityNotStartBg: require('@/assets/oneStage/ping/auth.png'),
      activityEndBg: require('@/assets/oneStage/ping/activity-end.png')
    }
  }
}

/** Vue.prototype.$getConfig(key)
 * key 包含以下几种
 * activityRulesTitle
 * activityRulesContent
 * activityRulesConfirmStorage
 * tipsDialogContent
 * authLoadingBg
} */

Vue.prototype.$getConfig = function (targetKey) {
  if (!isGuan() && !isPing()) {
    throw '不是瓶码或罐码'
  }

  let stageMap = {
    1: {
      value: 'oneStage'
    },
    2: {
      value: 'twoStage'
    }
  }

  let typeKey = isPing() ? 'ping' : 'guan'
  let stage = stageMap[getSessionStorage('stage')].value
  if (!stage) throw 'sessionStorage stage值有误'
  let target = config[typeKey][targetKey] || config[typeKey][stage][targetKey]
  if (!target) throw '$getConfig 参数有误'
  return isFunction(target) ? target() : target
}
