export default {
  title: '战马促销活动',
  tracking: {
    url: process.env.VUE_APP_LOG_URL + '/osa/logAgent/save',
    scene: {
      //页面进入前
      before: {
        // 路由名称
        Index: {
          userVisit: 6
        }
      },
      //页面进入后
      after: {},
      //返回页面
      back: {},
      //页面所有接口请求后埋点
      nextRequest: {
        Index: {
          userVisit: 2
        }
      }
    }
  }
}
