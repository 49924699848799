import {
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage
} from '@/utils/storage.js'

// 图片路径添加域名
export function imgPathAddDomain(data) {
  let v = ''
  if (isObject(data)) {
    v = {}
    Object.keys(data).forEach(key => {
      v[key] = imgPathAddDomain(data[key])
    })
  } else if (isArray(data)) {
    v = []
    data.forEach((val, index) => {
      v[index] = imgPathAddDomain(val)
    })
  } else {
    v = isLink(data)
      ? data
      : isImage(data)
      ? process.env.VUE_APP_BASE_PIC + data
      : data
  }
  return v
}

// 记录跳转链接的状态(用作判断浏览器返回时使用)并跳转到指定url
export function toLink(href, flag = true) {
  setSessionStorage('_isBack', flag)
  // 如果从实物奖跳转 清空
  if (flag == 'coupons') {
    removeSessionStorage('isTrackingBack')
    removeSessionStorage('backValue')
  }
  console.log(href)
  window.location.href = href
}

export function getIsBack() {
  return getSessionStorage('_isBack')
}

export function removeIsBack() {
  removeSessionStorage('_isBack')
}

export function isImage(str) {
  let reg = /\.(png|jpg|gif|jpeg|webp)$/
  return reg.test(str)
}

export function isLink(str) {
  let reg = /^(http|https):\/\/.*$/
  return reg.test(str)
}

export function isString(val) {
  return typeof val == 'string'
}

export function isBoolean(val) {
  return typeof val == 'boolean'
}

export function isObject(val) {
  return Object.prototype.toString.call(val) === '[object Object]'
}

export function isArray(val) {
  return Array.isArray(val)
}

export function isFunction(val) {
  return typeof val == 'function'
}

export function debounce(fn, delay) {
  let timer = null
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

//瓶装
export function isPing() {
  let projectCode = getSessionStorage('projectCode')
  if (projectCode == 32010761) {
    return true
  }
  return false
}

//罐装
export function isGuan() {
  let projectCode = getSessionStorage('projectCode')
  if (projectCode == 32010760) {
    return true
  }
  return false
}
