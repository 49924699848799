import request from '@/utils/request'

//项目校验
export function checkProject(data) {
  return request({
    url: '/projectCheck/checkProjectConfig',
    method: 'post',
    data
  })
}

//码校验
export function checkCode(data) {
  return request({
    url: '/projectCheck/codeCheck',
    method: 'post',
    data
  })
}

//微信code
export function wxCode(data) {
  return request({
    url: '/oneYuanBuy/getRedirectUrl',
    method: 'post',
    data
  })
}

//用户信息
export function userinfo(data) {
  return request({
    url: '/oneYuanBuy/getInfo',
    method: 'post',
    data
  })
}

//获取微信定位所需参数
export function locationParams(data) {
  return request({
    url: '/oneYuanBuy/locationParams',
    method: 'post',
    data
  })
}

//根据经纬度获取位置信息
export function location(data) {
  return request({
    url: '/location',
    method: 'post',
    data
  })
}

//项目阶段
export function projectStage(data) {
  return request({
    url: '/projectCheck/projectActivityRule',
    method: 'post',
    data
  })
}
