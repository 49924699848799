<template>
  <div class="support">
    <p class="seo">本活动由圈码提供技术支持</p>
  </div>
</template>

<script>
export default {
  name: 'support'
}
</script>

<style lang="scss" scoped>
.support {
  display: flex;
  justify-content: center;
  align-content: center;
  background: url('../../assets/support.png') no-repeat center top 100% / 100%
    #ececec;
  height: 122px;
  .seo {
    display: none;
  }
}
</style>
